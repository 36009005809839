<template>
  <ul class="wxStatistics">
          <li v-for="(value,key) in score " :key="key">
            <span>{{key}}</span>
            <div class="wxStatistics_tu">
              <div class="wxStatistics_tu_bj jin" :style="'width: ' + qk_score_ratio[key]">
              </div>
            </div>
            <i class="Jinshu">{{value}}</i>
          </li>
        </ul>
</template>

<script>


export default {
  props:{
    score:Object,
    qk_score_ratio:Object
  },
  data(){
    return {
      clssenum:{
        木:'mu',
        土:'tu',
        火:"huo",
        水:'shui',
        金:'jin'
      }
    }
  }
}
</script>


<style lang="less">

.wxStatistics {
  width: 90%;
  margin-top: 20px;
  padding-bottom: 5px;
}
.wxStatistics li {
  height: 35px;
  font-size: 13px;
  line-height: 25px;
  width: 100%;
  margin-bottom: 4px;
}
.wxStatistics li span {
  width: 25px;
  height: 25px;
  background: #f6c700;
  border-radius: 50%;
  text-align: center;
  float: left;
  margin-right: 11px;
  -webkit-box-shadow: 1px -2px 1px 1px rgba(0, 0, 0, 0.2) inset;
  -moz-box-shadow: 1px -2px 1px 1px rgba(0, 0, 0, 0.2) inset;
  -ms-box-shadow: 1px -2px 1px 1px rgba(0, 0, 0, 0.2) inset;
  box-shadow: 1px -2px 1px 1px rgba(0, 0, 0, 0.2) inset;
}
.wxStatistics li .wxStatistics_tu {
  width: 80%;
  height: 14px;
  display: block;
  float: left;
  background: #e5e5e5;
  margin: 7px 7px 0 0;
  border-radius: 20px;
}
.wxStatistics_tu_bj {
  height: 100%;
  border-radius: 20px;
  background: #f6c700;
  box-shadow: 1px -0.8px 1px 1px rgba(0, 0, 0, 0.2) inset;
  float: left;
  position: relative;
}

.wxStatistics li i {
  color: #c0222d;
  float: right;
}
.wxStatistics li:nth-child(2) span {
  background: #80c269;
}
.wxStatistics li:nth-child(2) .wxStatistics_tu_bj {
  background: #80c269;
}

.wxStatistics li:nth-child(3) span {
  background: #7ecef4;
}
.wxStatistics li:nth-child(3) .wxStatistics_tu_bj {
  background: #7ecef4;
}



.wxStatistics li:nth-child(4) span {
  background: #ec6941;
}
.wxStatistics li:nth-child(4) .wxStatistics_tu_bj {
  background: #ec6941;
}

.wxStatistics li:nth-child(5) span {
  background: #81511c;
}
.wxStatistics li:nth-child(5) .wxStatistics_tu_bj {
  background: #81511c;
}

</style>
<template>
  <div class="center">
    <table
      border="0"
      cellspacing="1"
      cellpadding="0"
      class="newdetailed"
      style="border-spacing:1px;border-collapse:separate"
    >
      <tbody>
        <tr>
          <td></td>
          <td>年柱</td>
          <td>月柱</td>
          <td>日柱</td>
          <td>时柱</td>
        </tr>
        <tr>
          <td v-if="sex == '男'">乾造</td>
          <td v-else>坤造</td>
          <td >{{data.bazi[0].y}}</td>
          <td >{{data.bazi[1].m}}</td>
          <td >{{data.bazi[2].d}}</td>
          <td >{{data.bazi[3].h}}</td>
        </tr>
        <tr>
          <td v-if="sex == '男'">乾造五行</td>
          <td v-else>坤造五行</td>
          <td>{{data.zao[0].y}}</td>
          <td>{{data.zao[1].m}}</td>
          <td>{{data.zao[2].d}}</td>
          <td>{{data.zao[3].h}}</td>

        </tr>

        <tr>
          <td>纳音</td>
          <td >{{data.nayin[0].y}}</td>
          <td >{{data.nayin[1].m}}</td>
          <td >{{data.nayin[2].d}}</td>
          <td >{{data.nayin[3].h}}</td>
        </tr>
        <tr>
          <td>命理</td>
          <td colspan="4">{{data.ml}}命</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  props: {
    data: Object,
    sex:String
  },
  created(){
    console.log(this.sex)
  }
}
</script>


<style scoped>

.newdetailed {
  width: 100%;
  background: #c09d65;
}
.newdetailed tr {
  height: 40px;
}
.newdetailed tr td {
  width: 20%;
  background: #fff;
  text-align: center;
  padding: 5px 0;
}
.newdetailed tr:first-child td {
  background: #f2eedf;
}
.newdetailed tr:nth-child(3) td,
.newdetailed tr:nth-child(4) td,
.newdetailed tr:nth-child(6) td {
  background: #f2eedf;
}
.newdetailed tr:nth-child(4) td,
.newdetailed tr:nth-child(5) td,
.newdetailed tr:nth-child(7) td {
  background: #fff;
}
.newdetailed tr td:first-child {
  background: #f2eedf;
}
</style>

<template>
  <ul class="Weinamelist">
    <li v-for="item in data" :key="item.id">
      <i>
        <div class="Weinamelist_l">
          <div v-for="(value , index) in item.pinyin" :key="value" class="WeinameLl_Con">
            <p>{{value}}</p>
            <span>{{item.name[index]}}</span><i>[{{item.asktaoval[index]}}]</i>
          </div>
        </div>
        <div class="Weinamelist_r">
          <span class="collect" @click="gotodetail(item.id)">详细解析></span>
        </div>
      </i>
      <p class="text-yiyun" style="display:block">
        <template v-for="(value,key) in item.means">
            <span :key="key">◎{{key}}</span>
              {{value}}
              <br />
        </template>
       
      </p>
    </li>
  </ul>
</template>

<script>
export default {
  props:{
    data:Array
  },
  data(){
    return {
      name:[1,2,3,4,5,6,77,8,7,1,1,2,3]
    }
  },
  methods:{
    gotodetail(id){
      this.$emit('click',id)
    }
  }
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}
.Weinamelist {
  width: 100%;
  overflow: hidden;
  padding: 0 10px; /*position:absolute;top:2.7rem;left:0;*/
  margin-top: 20px;
  box-sizing: border-box;
}
.Weinamelist li {
  overflow: hidden;
  border: 1px dashed #bd9a7f;
  border-radius: 5px;
  padding-left: 0;
  margin-top: 12px;
}
.Weinamelist li > i {
  display: block;
  float: left;
  width: 100%;
  overflow: hidden;
  padding-left: 7px;
  border-bottom: 1px dotted #bd9a7f;
  box-sizing: border-box;
}
.Weinamelist_l {
  width: 64%;
  overflow: hidden;
  float: left;
}
.WeinameLl_Con {
  width: 50px;
  height: 105px;
  margin-right: 8px;
  padding-top: 8px;
  float: left;
}
.WeinameLl_Con:last-child {
  margin-right: 0 !important;
}
.WeinameLl_Con p {
  font-size: 16px;
  color: #383838;
  line-height: 25px;
  text-align: center;
  width: 100%;
}
.WeinameLl_Con span {
  width: 45px;
  height: 45px;
  background: url(//cdn.names.org.cn/website/Content/szname/img/NewNameBjk.png)
    no-repeat;
  background-size: 100%;
  font-size: 25px;
  text-align: center;
  line-height: 45px;
  display: block;
  margin: 0 auto;
}
.WeinameLl_Con i {
  width: 100%;
  line-height: 22px;
  text-align: center;
  font-size: 12px;
  color: #c33936;
  display: block;
}
.WeinameLl_Con:last-child {
  margin-right: 0;
}
.Weinamelist_r {
  width: 36%;
  float: left;
  display: block;
  overflow: hidden;
  height: 110px;
  line-height: 110px;
}
.Weinamelist_r span {
  display: inline-block;
  width: auto;
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  margin-left: 13px;
  position: relative;
}
.Weinamelist_r .collect i {
  width: 16px;
  height: 16px;
  background: url(//cdn.names.org.cn/website/Content/szname/img/icon.png)
    no-repeat 0 -20px;
  background-size: 50px;
  display: block;
  position: absolute;
  top: -8px;
  left: calc(50% - 8px);
}
.collectdinw {
  background-position: -34px -20px !important;
}
.Weinamelist_r .parsing:before {
  content: "";
  width: 16px;
  height: 16px;
  display: block;
  position: absolute;
  top: -8px;
  left: calc(50% - 8pxm);
  background: url(//cdn.names.org.cn/website/Content/szname/img/icon.png)
    no-repeat 0 -50px;
  background-size: 50px;
}

.text-yiyun {
  overflow: hidden;
  float: left;
  text-align: left;
  font-size: 14px;
  color: #333;
  line-height: 22px;
  padding: 11px;
  letter-spacing: 1px;
}
.text-yiyun > span {
  color: #bf2525;
}
</style>
